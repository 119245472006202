import React from 'react'
import {NavLink} from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const {t} = useTranslation()

  return (
    <div>
    <div className="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5" style={{"margin-top": "90px"}}>
        <div className="row pt-5">
            <div className="col-lg-4 col-md-6 mb-5">
                <NavLink to="/" className="navbar-brand">
                <img className="img-fluid" src="user/my_img/Zanzibar&Markiem.png"  width="200" height="auto" />
                </NavLink>
                <h6 className="text-white text-uppercase mt-4 mb-3" style={{"letter-spacing": "5px"}}>{t('follow')}</h6>
                <div className="d-flex justify-content-start">
                    {/* <NavLink className="btn btn-outline-primary btn-square mr-2" to="#"><i className="fab fa-twitter"></i></NavLink> */}
                    <NavLink className="btn btn-outline-primary btn-square mr-2" target="_blank" to="https://www.facebook.com/zanzibarzmarkiem?mibextid=2JQ9oc"><i className="fab fa-facebook-f"></i></NavLink>
                    <NavLink className="btn btn-outline-primary btn-square mr-2" target="_blank" to="https://wa.me/message/H4SOOIZEK2NMH1"><i className="fab fa-whatsapp"></i></NavLink>
                    <NavLink className="btn btn-outline-primary btn-square" target="_blank"  to="https://instagram.com/zanzibar_z_markiem?utm_source=qr"><i className="fab fa-instagram"></i></NavLink>
                </div>
                 {/* https://wa.me/message/H4SOOIZEK2NMH1 */}
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('service')}</h5>
                <div className="d-flex flex-column justify-content-start">
                    <NavLink className="text-white-50 mb-2" to="/TanzaniaSafari"><i className="fa fa-angle-right mr-2"></i>{t('tan_tour')}</NavLink>
                    <NavLink className="text-white-50 mb-2" to="/ZanzibarTour"><i className="fa fa-angle-right mr-2"></i>{t('zan_tour')}</NavLink>
                    <NavLink className="text-white-50 mb-2" to="/Photoshot&Drown"><i className="fa fa-angle-right mr-2"></i>{t('photoshot_drawn')}</NavLink>
                    {/* <NavLink className="text-white-50 mb-2" to="/Hotel"><i className="fa fa-angle-right mr-2"></i>Accommodation</NavLink> */}
                    {/* <NavLink className="text-white-50 mb-2" to="/Bangalow"><i className="fa fa-angle-right mr-2"></i>Bangalow Booking</NavLink> */}
                    <NavLink className="text-white-50 mb-2" to="/Tranfer"><i className="fa fa-angle-right mr-2"></i>{t('tranfers')}</NavLink>
                    <NavLink className="text-white-50" to="/PlanTravel"><i className="fa fa-angle-right mr-2"></i>{t('Plan_your_trip')}</NavLink>
                </div>
            </div>
         
            <div className="col-lg-4 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('contacts')}</h5>
                <p><i className="fa fa-map-marker-alt mr-2"></i>7113 Street, Stone Town,Zanzibar</p>
                <p><i className="fa fa-phone-alt mr-2"></i>+255 620 362 827</p>
                <p><i className="fa fa-envelope mr-2"></i>info@zanzibarzmarkiem.com</p>
                {/* <h6 className="text-white text-uppercase mt-4 mb-3" style={{"letter-spacing": "5px"}}>Newsletter</h6>
                <div className="w-100">
                    <div className="input-group">
                        <input type="text" className="form-control border-light" style={{"padding": "25px"}} placeholder="Your Email" />
                        <div className="input-group-append">
                            <button className="btn btn-primary px-3">Sign Up</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    {/* <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style={{"border-color": rgba(256, 256, 256, .1) !important}}> */}
    <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style={{"border-color": "rgba(256, 256, 256, .1)"}}>
        <div className="row">
            <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                <p className="m-0 text-white-50">Copyright &copy; <a href="#">Zanzibar z Markiem. All Rights Reserved.</a>
                </p>
            </div>
            <div className="col-lg-6 text-center text-md-right">
                {/* <p className="m-0 text-white-50">Designed by <a href="#">Salum</a></p> */}
            </div>
        </div>
    </div>
        </div> 
  )
}

export default Footer
