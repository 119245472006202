import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";
const SelousPark = () => {
    
const {t} = useTranslation()

    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                                 <img
                                  src="user/my_img/IMG-20230924-WA0170.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/IMG-20230924-WA0170.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0169.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (17).jpeg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0245.jpg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (6).jpeg"
                                    alt="Thumbnail 4"
                                  />


                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (18).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (7).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0198.jpg"
                                    alt="Thumbnail 4"
                                  />
                              
                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
          
                            {/* <h2 class="mb-3"></h2> */}
                            <p><b className='text-primary'>SELOUS PARK(nyerere)</b> {t('selous_01')}</p>
                            <p>{t('selous_1')}</p>

                            <p>{t('selous_2')}</p>

                            <hr />
                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('trip_plan')}</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan5')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan7')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_plan8')}</p>
                            <hr />

                              <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price_include')}</h4>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include2')}</p>
                                <p ><i className="fa fa-angle-right text-primary mr-2"></i> {t('selous_include3')}</p>
                               <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include4')}</p>
                              <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include5')}</p>
                               


                        </div>
                    </div>

    </div>
  )
}
export default SelousPark