import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";

const AquaticSafari = () => {
  const {t} = useTranslation()

    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);
  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                                 <img
                                  src="user/my_img/IMG-20230924-WA0235.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/IMG-20230924-WA0235.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.53.27.jpeg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_15981058443279415.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.55.02.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.55.02 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/received_892362514568202_1.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/WhatsApp Image 2023-10-19 at 12.31.08 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0240.jpg"
                                    alt="Thumbnail 6"
                                  />
                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
                            <p><b className='text-primary'>MNEMBA SNORKLING: </b> {t('mnemba1')}</p>
                            <p><b className='text-primary'>MNEMBA SNORKLING: </b>{t('mnemba2')}</p>
                            <p><b className='text-primary'>MNEMBA SNORKLING: </b>{t('mnemba3')}</p>
                            <p><b className='text-primary'>MNEMBA SNORKLING: </b>{t('mnemba4')}</p>
                          
                              <hr />
                              <p><b className='text-primary'>NUGWI AQUARIUM: </b>{t('nungwi')}</p>
                              <hr />

                            <p><b className='text-primary'>KENDWA BEACH: </b>{t('kendwa')}</p>
                            <hr />

                            
                        </div>
                    </div>
                    

    </div>
  )
}

export default AquaticSafari
