import React from 'react'
import Head from '../include/Head'
import Nav from '../include/Nav'
import Slider from './Slider'
import BangalowPakage from './BangalowPakage'
import Footer from '../include/Footer'

const Bangalow = () => {
  return (
    <div>
      <Head />
      <Nav />
      <Slider/>
      <BangalowPakage/>
      <Footer />
    </div>
  )
}

export default Bangalow
