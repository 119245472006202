import React from 'react'
import { useTranslation } from "react-i18next";

const Slider = () => {
const {t} = useTranslation()

  return (
    <div className="container-fluid page-header">
    <div className="container">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{"min-height": "400px"}}>
            <h3 className="display-4 text-white text-uppercase">{t('contact')}</h3>
            {/* <div className="d-inline-flex text-white">
                <p className="m-0 text-uppercase"><a className="text-white" href="">Home</a></p>
                <i className="fa fa-angle-double-right pt-1 px-3"></i>
                <p className="m-0 text-uppercase">Contact</p>
            </div> */}
        </div>
    </div>
</div>
  )
}

export default Slider
