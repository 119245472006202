import React, { useEffect } from 'react';
import '../image.css';

const NatureZanzibarGallery = () => {
    
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            <img
                                  src="user/my_img/20201023_120110.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/20201023_120110.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_16265860865890646.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/IMG-20200327-WA0011.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0159.jpg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0173.jpg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0201.jpg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0205.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0206.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.29 (5).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.29 (3).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.56.54.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (28).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (8).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.23.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.43 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />

<img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.43 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.43.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.44 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.44.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.45 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />

<img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.45 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.45.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.46 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.46 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/nature/WhatsApp Image 2023-10-23 at 18.17.46.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                                                     
                                </div>
                            </div>
                        </div>
                       
                    </div>
    </div>
  )
}

export default NatureZanzibarGallery