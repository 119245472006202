import React from 'react'
import Head from '../include/Head'
import Nav from '../include/Nav'
import PhotoSlider from './PhotoSlider'
import PhotoForm from './PhotoForm'
import Footer from '../include/Footer'

const Photo = () => {
  return (
    <div>
      <Head />
      <Nav />
      <PhotoSlider />
      <PhotoForm />
      <Footer />
    </div>
  )
}

export default Photo
