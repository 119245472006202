import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";
const SelousPark2 = () => {
const {t} = useTranslation()
    
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            <img
                                  src="user/my_img/IMG-20230924-WA0190.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/IMG-20230924-WA0190.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.54.59.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.49.13.jpeg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.50.38.jpeg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.51.17.jpeg"
                                    alt="Thumbnail 4"
                                  />  
                                   <img
                                    src="user/my_img/IMG-20230924-WA0193.jpg"
                                    alt="Thumbnail 4"
                                  />   
                                    <img
                                    src="user/my_img/IMG-20230924-WA0198.jpg"
                                    alt="Thumbnail 4"
                                  />                                      
                                  <img
                                  src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.44 (1).jpeg"
                                  alt="Thumbnail 4"
                                />                                                     

                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
          
                            <h2 class="mb-3 text-primary">SELOUS PARK (NYERERE)</h2>
                            <p><b className='text-primary'> SELOUS PARK (NYERERE)</b> {t('selous2_1')}</p>
                            <p> {t('selous2_2')}</p>

                            
                              <hr />
                            <h4 className="text-uppercase mb-4 text-primary" style={{"letter-spacing": "5px"}}>{t('day')} 1</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day1_1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i> {t('selous2_day1_2')}</p>
                            <p> <i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day1_3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day1_4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day1_5')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day1_6')}</p>
                              
                            <hr />
                            <h4 className="text-uppercase mb-4 text-primary" style={{"letter-spacing": "5px"}}>{t('day')} 2</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_1')}</p> 
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_3')}</p> 
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_4')} </p> 
                            <p> <i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_5')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_6')}</p> 
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_7')}</p>
                            <hr />

                               <h4 className="text-uppercase mb-4 text-primary" style={{"letter-spacing": "5px"}}> {t('price_include')} </h4>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_price_1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i> {t('selous2_day2_price_2')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i> {t('selous2_day2_price_3')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i> {t('selous2_day2_price_4')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_price_5')}</p>
                                <p> <i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_price_6')}</p>
                                
                        </div>
                    </div>
    </div>
  )
}

export default SelousPark2