import React from 'react'

const Slider = () => {
  return (
    <div className="container-fluid page-header-wealth">
        <div className="container">
            <div className="d-flex flex-column align-items-center justify-content-center" style={{"min-height": "400px"}}>
                <h3 className="display-4 text-white text-uppercase">WEALTH OF ZANZIBAR</h3>
                <div className="d-inline-flex text-white">
                    {/* <p className="m-0 text-uppercase"><a className="text-white" href="">Home</a></p> */}
                    {/* <i className="fa fa-angle-double-right pt-1 px-3"></i> */}
                    {/* <p className="m-0 text-uppercase">3W1(spice farm + prison island + stonetown)</p> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Slider
