import React from 'react'
import Head from '../include/Head'
import Nav from '../include/Nav'
import Slider from '../tantour/Slider'
// import Booking from '../tantour/Booking'
import Tanpakage from '../tantour/Tanpakage'

import Footer from '../include/Footer'

const TanTour = () => {
  return (
    <div>
      <Head />
      <Nav />
      <Slider />
      {/* <Booking /> */}
      <Tanpakage />
      <Footer />
    </div>
  
  )
}

export default TanTour
