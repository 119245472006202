import React, { useEffect,useState } from 'react';
import './image.css';
import TravelBooking from '../../TravelBooking';
import PungumeIsland from '../../../tourplace/PungumeIsland';
import { useTranslation } from "react-i18next";
const PungumeDetail = () => {
const {t} = useTranslation()
const [userServicesValue, setUserServicesValue] = useState("PUNGUME ISLAND");

  useEffect(() => {
    const thumbnails = document.getElementById("thumbnails");
    const imgs = thumbnails.getElementsByTagName("img");
    const main = document.getElementById("main");

    for (let i = 0; i < imgs.length; i++) {
      const img = imgs[i];

      img.addEventListener("click", () => {
        main.src = img.src;
      });
    }
  }, []);
  
  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">
              
                    <PungumeIsland />
                
                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">
                    
                   <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('trip_plan')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan2')}</p>
                            <p><i  className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan5')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan7')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('pungure_plan8')}</p>
                        </div>
                    </div>

                    <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('pungume_title')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                       
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan2')}</p>
                                <p><i  className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan3')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan4')}</p>
                                <p><i  className="fa fa-angle-right text-primary mr-2 "></i>{t('safari_plan5')}</p>
                                <p><i  className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan6')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan7')}</p>
                                <p><i  className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan8')}</p>
                               
                           
                        </div>
                    </div>
                    <TravelBooking value={userServicesValue} /> 
                    
                  
                </div>
            </div>
        </div>
    </div>
  )
}

export default PungumeDetail
