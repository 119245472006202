import React from 'react'
import {NavLink} from "react-router-dom";
import { useTranslation } from "react-i18next";

const About = () => {
    const {t} = useTranslation()

  return (
    <div className="container-fluid py-5">
    <div className="container pt-5">
        <div className="row">
            <div className="col-lg-6" style={{"min-height": "500px"}}>
                <div className="position-relative h-100">
                    <img className="position-absolute w-100 h-100" src="user/my_img/IMG-20230924-WA0146.jpg" style={{"object-fit" : "cover"}}/>
                </div>
            </div>
            <div className="col-lg-6 pt-5 pb-lg-5">
                <div className="about-text bg-white p-4 p-lg-5 my-lg-5">
                    <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('about_us')}</h6>
                    <h1 className="mb-3">{t('about')}</h1>
                   
                    <p> {t('who_i_am')}</p>
                    <p className='mt-2'> {t('who_i_am1')}</p>
                    <p className='mt-1'> {t('who_i_am2')}</p>
                    <div className="row mb-4">
                        <div className="col-6">
                            <img className="img-fluid" src="user/my_img/IMG-20230924-WA0169.jpg" alt="" />
                        </div>
                        <div className="col-6">
                            <img className="img-fluid" src="user/my_img/IMG-20230924-WA0250.jpg" alt="" />
                        </div>
                    </div>
                    {/* <NavLink to="/PlanTravel" className="btn btn-primary mt-1">{t('book_now')}</NavLink> */}
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default About
