import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";

const WealthZanzibar = () => {
  const {t} = useTranslation()
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);
  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                                 <img
                                  src="user/my_img/20191026_105613.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/20191026_105613.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (5).jpeg"
                                    alt="Thumbnail 2"
                                  /> 
                                
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 12.23.55 (1).jpeg"
                                    alt="Thumbnail 4"
                                  /> 
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 12.27.49.jpeg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 12.28.05.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                 
                                   <img
                                    src="user/my_img/IMG-20230924-WA0167.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0244.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20220810-WA0028.jpg"
                                    alt="Thumbnail 6"
                                  />
                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
                        
                            <p><b className='text-primary'>SPICE FARM: </b>{t('spice_farm')}</p><hr />
                            <p><b className='text-primary'>PRISON ISLAND: </b>{t('proson_island')}</p><hr />
                            <p><b className='text-primary'>STONE TOWN: </b>{t('stone_town')}</p><hr />
                        </div>
                    </div>
                    

    </div>
  )
}

export default WealthZanzibar
