import React, { useEffect } from 'react';
import Head from '../include/Head'
import Footer from '../include/Footer'
import Nav from '../include/Nav'
import Slider from './Slider'
import About from './About'
// import Fuature from './Fuature'
import Popular from './Popular'
import Service from './Service'
import Safari from './Safari'
// import Trans from './Trans'
// import Client from './Client'
const Index = () => {

  return (
    <div>
        <Head />
        <Nav />
        <Slider />
        <About />
        {/* <Fuature /> */}
        <Safari />
        <Service />
        <Popular />
        {/* <Trans /> */}
        {/* <Client /> */}
        <Footer />

    </div>
  )
}

export default Index
