import React from 'react'
import {NavLink} from "react-router-dom";
import { useTranslation } from "react-i18next";
const Safari = () => {
    const {t} = useTranslation()
  return (
    <div className="container-fluid">
    <div className="container pt-5 pb-3">
        <div className="text-center mb-3 pb-3">
            <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('pakage')}</h6>
                </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20200327-WA0011.jpg" alt="" style={{"width":"100%", "height":"100%"}}></img>
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Zanzibar</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>1 {t('day')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/ZanDetail">Nature of Zanzibar</NavLink>
                        <p>(JOZANI forest + Rock restaurant + PAJE beach + Seaweed) </p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0244_1.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Zanzibar</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>1 {t('day')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/ZanWealth">Wealth of Zanzibar 3in1</NavLink>
                        <p>(spice farm + prison island + stonetown)</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0250.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Zanzibar</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>1 {t('day')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/SafariBlue">Safari Blue</NavLink>
                        <p>Safari Blue, with lunch (Full day)</p>
                        <div className="border-top mt-5 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/pungume_new.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Zanzibar</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>1 {t('day')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/PungumeIsland">Pungume island</NavLink>
                        <div className="border-top mt-5 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0170.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>1 {t('day')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/MikumiSafari">Safari in MIKUMI PARK departure from Zanzibar</NavLink>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0199_1.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>3 {t('days')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/MikumiSafari3day">LAKE MANYARA + TARANGIRE PARKU + NGORONGORO PARKU</NavLink>
                        <div className="border-top mt-6 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Safari
