import './App.css';
import Home from './component/Home/Home';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ZanTour from './component/zantour/ZanTour';
import TanTour from './component/tantour/TanTour';
import Contact from './component/countant/Contact';
import Hotel from './component/hotel/Hotel';
import Bangalow from './component/bangalaw/Bangalow';
import Plan from './component/plan/Plan';
import Tranfer from './component/Tranfer/Tranfer';
import Photo from './component/photoshoot/Photo';
import ZanTourDetail from './component/zandetail/ZanTourDetail';
import WealthDetail from './component/services/wealthZanzibar/WealthTourDetail';
import SafairBlueTourDetail from './component/services/safariBlue/SafairBlueTourDetail';
import AquaticTourDetail from './component/services/aquaticSafari/AquaticTourDetail';
import PungumeTourDetail from './component/services/pungumeIsland/PungumeTourDetail';
import MikumiTourDetail from './component/services/1daysafarimikumi/MikumiTourDetail';
import Selous2TourDetail from './component/services/2daysafariselous/Selous2TourDetail';
import SelosParkTourDetail from './component/services/1daysafariselous/SelosParkTourDetail';
import SerengetiDetailTourDetail from './component/services/3dayserengeti/SerengetiDetailTourDetail';
import MikumiTourDetail3day from './component/services/3daynyasa/MikumiTourDetail3day';
import PrisonIslandGalleryTour from './component/gallery/prison/PrisonIslandGalleryTour';
import SafariBlueTour from './component/gallery/safari/SafariBlueTour';
import TarangireTourDetail from './component/services/4daytarangire/TarangireTourDetail';
import Tangire5daysTourDetail from './component/services/5daytarangie/Tangire5daysTourDetail';
import AquaticSafariGalleryTour from './component/gallery/aquaticSafari/AquaticSafariGalleryTour';
import NatureZanzibarGalleryTour from './component/gallery/natureZanzibar/NatureZanzibarGallery';
import WealthZanzibarGalleryTour from './component/gallery/wealthZanzibar/WealthZanzibarGalleryTour';
import TanSafariGalleryTour from './component/gallery/tansafari/TanSafariGalleryTour';

function App() {

  return (
    <div>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/ZanzibarTour" element={<ZanTour />} />
        <Route exact path="/TanzaniaSafari" element={<TanTour />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/Hotel" element={<Hotel />} />
        <Route exact path="/Bangalow" element={<Bangalow />} />
        <Route exact path="/PlanTravel" element={<Plan />} />
        <Route exact path="/Tranfer" element={<Tranfer />} />
        <Route exact path="/Photoshot&Drown" element={<Photo />} />
        <Route exact path="/ZanDetail" element={<ZanTourDetail />} />
        <Route exact path="/ZanWealth" element={<WealthDetail />} />
        <Route exact path="/SafariBlue" element={<SafairBlueTourDetail />} />
        <Route exact path="/AquaticSafari" element={<AquaticTourDetail />} />
        <Route exact path="/PungumeIsland" element={<PungumeTourDetail />} />
        <Route exact path="/MikumiSafari" element={<MikumiTourDetail />} />
        <Route exact path="/Selous1day" element={<SelosParkTourDetail />} />
        <Route exact path="/Selous2day" element={<Selous2TourDetail />} />
        <Route exact path="/Serengeti3day" element={<SerengetiDetailTourDetail />} />
        <Route exact path="/MikumiSafari3day" element={<MikumiTourDetail3day />} />
        <Route exact path="/TarangireSafari" element={<TarangireTourDetail />} />
        <Route exact path="/Tarangire5daysSafari" element={<Tangire5daysTourDetail />} />
        <Route exact path="/PrisonIslandGallery" element={<PrisonIslandGalleryTour />} />
        <Route exact path="/SafariBlueGallery" element={<SafariBlueTour />} />
        <Route exact path="/AquaticSafariGallery" element={<AquaticSafariGalleryTour />} />
        <Route exact path="/NatureZanzibarGallery" element={<NatureZanzibarGalleryTour />} />
        <Route exact path="/WealthZanzibarGallery" element={<WealthZanzibarGalleryTour />} />
        <Route exact path="/TanzaniaSafariGallery" element={<TanSafariGalleryTour />} />

      </Routes>
    </Router>
  </div>
  );
}

export default App;
