import React from 'react'
import Slider from './Slider'

import Head from '../../include/Head'
import Nav from '../../include/Nav'
import Footer from '../../include/Footer'
import PrisonIslandGallery from './PrisonIslandGallery'

const PrisonIslandGalleryTour = () => {
  return (
    <div>
      <Head/>
      <Nav />
      <Slider />
      <PrisonIslandGallery />
      <Footer />
    </div>
  )
}

export default PrisonIslandGalleryTour
