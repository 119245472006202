import React, { useState  } from 'react';
import TravelBooking from '../../TravelBooking';
import TarangireSafari from '../../../tourplace/TarangireSafari';
import { useTranslation } from "react-i18next";

const TarangireDetail = () => {
const {t} = useTranslation()
const [userServicesValue, setUserServicesValue] = useState("4 days, 3 days : TARANGIRE PARKU + SERENGETI PARKU + NGORONGORO PARKU");
  
  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">

                    <TarangireSafari />

                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">
                <TravelBooking value={userServicesValue} /> 

                   <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price')} {t('language_1')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <ul className="list-inline m-0">
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>2 {t('persons')} -2200$/{t('person')}</a>
                                  
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>3 {t('persons')} - 1900$/{t('person')}</a>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>4 {t('persons')} - 1800 $ / {t('person')}</a>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>5 {t('persons')} - 1700$/{t('person')}</a>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>6 {t('persons')} - 1600$/{t('person')}</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
        
                    <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price')} {t('language_2')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <ul className="list-inline m-0">
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>2 {t('persons')} - $2400/{t('person')}</a>
                                  
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>3 {t('persons')} - 2100$/{t('person')}</a>
                                </li>

                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>4 {t('persons')} - 1950 $ / {t('person')}</a>
                                </li>

                                <li className="mb-3 d-flex justify-content-between align-items-center mt-2">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>5 {t('persons')} -1850$/{t('person')}</a>
                                </li>

                                <li className="mb-3 d-flex justify-content-between align-items-center mt-2">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>6 {t('persons')} -1730$/{t('person')}</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TarangireDetail
