import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";

const TarangireSafari = () => {
const {t} = useTranslation()

    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            <img
                                  src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.55.05.jpeg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.55.05.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.53.29.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.56.05.jpeg"
                                    alt="Thumbnail 1"
                                  />
  

                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.56.22 (1).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                    <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.20 (6).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                    <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.54.15.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                    <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.44.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                    <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.19 (1).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   
                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
                            <h2 class="mb-3">4 {t('days')} / 3 {t('nights')} : TARANGIRE PARKU + SERENGETI PARKU + NGORONGORO PARKU</h2>
                            {/* <h5 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>Safari Program</h5> */}
                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('day')} 1</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_5')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_7')}</p>
                            <hr />

                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('days')} 2</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day2_1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day2_2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('tarangire_1')}</p>
                            <hr />
                            
                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('days')} 3</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('tarangire_2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('tarangire_3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('tarangire_4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('tarangire_5')}</p>
                            <hr />
                
                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('days')} 4</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_5')}</p>
                            <hr />
                                <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price_include')}</h4>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('manyara_price_1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('manyara_price_2')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('manyara_price_3')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('manyara_price_4')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('manyara_price_5')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('manyara_price_6')}</p>
                                <hr />
                                
                                <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}> {t('price_with')}</h4>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_2')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_3')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_4')}</p>
                            
                                
                        </div>
                    </div>

    </div>
  )
}

export default TarangireSafari
