import React from 'react'
import Slider from './Slider'

import Head from '../../include/Head'
import Nav from '../../include/Nav'
import Footer from '../../include/Footer'
import MikumiDetail from './MikumiDetail'

const MikumiTourDetail = () => {
  return (
    <div>
      <Head/>
      <Nav />
      <Slider />
      <MikumiDetail />
      <Footer />
    </div>
  )
}

export default MikumiTourDetail
