import React, { useRef,useState  } from 'react';
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';

const TranferForm = () => {

const {t} = useTranslation()
const form = useRef();

const [formData, setFormData] = useState({
    leader_name: '',
    user_email: '',
    phone_number:'', 
    user_traveler:'',
    user_date:'',
    message: '',
    user_flight:'',
    user_airline:'',
    user_hotel:''
});

const [errors, setErrors] = useState({});

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear error when user starts typing
};



const validateForm = () => {
    const newErrors = {};

    // Validate leader name
    if (!formData.leader_name.trim()) {
      newErrors.leader_name = true;
    } else {
      newErrors.leader_name = false;
    }

    if (!formData.phone_number.trim()) {
        newErrors.phone_number = true;
      } else {
        newErrors.phone_number = false;
      }


      if (!formData.user_traveler.trim()) {
        newErrors.user_traveler = true;
      } else {
        newErrors.user_traveler = false;
      }

      if (!formData.user_date.trim()) {
        newErrors.user_date = true;
      } else {
        newErrors.user_date = false;
      }

      if (!formData.message.trim()) {
        newErrors.message = true;
      } else {
        newErrors.message = false;
      }

      if (!formData.user_flight.trim()) {
        newErrors.user_flight = true;
      } else {
        newErrors.user_flight = false;
      }

      if (!formData.user_airline.trim()) {
        newErrors.user_airline = true;
      } else {
        newErrors.user_airline = false;
      }

      if (!formData.user_hotel.trim()) {
        newErrors.user_hotel = true;
      } else {
        newErrors.user_hotel = false;
      }

   
    if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      newErrors.user_email = true;
    } else {
      newErrors.user_email = false;
    }

    // Other validations for different fields can be added here

    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    setErrors(newErrors);

    if (!Object.values(newErrors).some((error) => error)) {
      emailjs
        .sendForm('service_j8gq8rh', 'template_qsazudw', form.current, 'TAfVVQYOsK0uBn0uE')
        .then((result) => {
          console.log(result.text);
          if (result.text === 'OK') {
            alert('Booking is successful');
          }
        })
        .catch((error) => {
          console.error('Failed to send the email:', error);
          alert('Failed to send the email. Please try again later.');
        });

      setFormData({
        leader_name: '',
        user_email: '',
        phone_number:'', 
        user_traveler:'',
        user_date:'',
        message: '',
        user_flight:'',
        user_airline:'',
        user_hotel:'',
      });
    }
  };

  // Function to get today's date in the format "YYYY-MM-DD"
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };


  return (
    <div className="container-fluid py-5">

        <div className="text-center mb-3 pb-3">
            <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('tranfers')}</h6>
        </div>

        <div class="container pb-3">
                       
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
                            <p><b className='text-primary'>{t('tranfee_1')}</b></p>
                            <p>{t('tranfee_2')}</p>
                        </div>
        </div>


      <div className="container py-5">
       <div className="row justify-content-center">
            <div className="col-lg-8">
                <div className="contact-form bg-white " style={{"padding": "30px"}}>
                    <div id="success"></div>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-row">
                            <div className="control-group col-sm-6">
                                <label>{t('form_leader')}</label>
                                <input type="text" 
                                  className={`form-control ${errors.leader_name ? 'red-input' : ''}`}
                                  name="leader_name"
                                  value={formData.leader_name}
                                  onChange={handleInputChange} />
                            </div>
                            <div className="control-group col-sm-6">
                            <label>{t('form_email')}</label>
                                <input type="email" id="email" 
                                     className={`form-control ${errors.user_email ? 'red-input' : ''}`}
                                     name="user_email"
                                     value={formData.user_email}
                                     onChange={handleInputChange} />
                              
                            </div>
                        </div>

                         <div className="form-row mt-3">
                            <div className="control-group col-sm-6">
                               <label>{t('form_hotel')}</label>
                                <input type="text"
                                 className={`form-control ${errors.user_hotel ? 'red-input' : ''}`}
                                 name="user_hotel"
                                 value={formData.user_hotel}
                                 onChange={handleInputChange}
                                 />
                              
                            </div>
                            <div className="control-group col-sm-6">
                               <label>{t('form_date')}</label>
                                <input type="date"  className={`form-control ${errors.user_date ? 'red-input' : ''}`}
                                  name="user_date"
                                  value={formData.user_date}
                                  onChange={handleInputChange} min={getCurrentDate()}  />
                              
                            </div>
                        </div>

                        <div className="form-row mt-3">
                            <div className="control-group col-sm-6">
                               <label>{t('form_air')}</label>
                                <input type="text"  className={`form-control ${errors.user_airline ? 'red-input' : ''}`}
                                  name="user_airline"
                                  value={formData.user_airline}
                                  onChange={handleInputChange} />
                              
                            </div>
                            <div className="control-group col-sm-6">
                               <label>{t('form_flight')}</label>
                                <input type="time"  className={`form-control ${errors.user_flight ? 'red-input' : ''}`}
                                  name="user_flight"
                                  value={formData.user_flight}
                                  onChange={handleInputChange} />
                              
                            </div>
                        </div>

                        <div className="form-row mt-3">
                            <div className="control-group col-sm-6">
                               <label>{t('form_phone')}</label>
                                <input type="text"  className={`form-control ${errors.phone_number ? 'red-input' : ''}`}
                                  name="phone_number"
                                  value={formData.phone_number}
                                  onChange={handleInputChange} />
                              
                            </div>
                            <div className="control-group col-sm-6">
                               <label>{t('form_people')}</label>
                                <input type="number"  className={`form-control ${errors.user_traveler ? 'red-input' : ''}`}
                                  name="user_traveler"
                                  value={formData.user_traveler}
                                  onChange={handleInputChange}/>
                              
                            </div>
                        </div>


                        <div className="control-group mt-3">
                          <label>{t('form_message')}</label>
                            <textarea  rows="5" id="message"
                                 className={`form-control ${errors.message ? 'red-input' : ''}`}
                                 name="message"
                                 value={formData.message}
                                 onChange={handleInputChange}
                                 ></textarea>
                          
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary py-3 px-4 mt-3" type="submit" id="sendMessageButton">{t('book')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default TranferForm
