import React, { useRef,useState  } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

const ContactPage = () => {
const {t} = useTranslation()
const form = useRef();

const [formData, setFormData] = useState({
    leader_name: '',
    user_email: '',
    user_services: '',
    message: ''
});

const [errors, setErrors] = useState({});

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear error when user starts typing
};


const validateForm = () => {
    const newErrors = {};

    // Validate leader name
    if (!formData.leader_name.trim()) {
      newErrors.leader_name = true;
    } else {
      newErrors.leader_name = false;
    }

      if (!formData.user_services.trim()) {
        newErrors.user_services = true;
      } else {
        newErrors.user_services = false;
      }
      
      if (!formData.message.trim()) {
        newErrors.message = true;
      } else {
        newErrors.message = false;
      }

    // Validate email format
    if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      newErrors.user_email = true;
    } else {
      newErrors.user_email = false;
    }

    // Other validations for different fields can be added here

    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    setErrors(newErrors);

    if (!Object.values(newErrors).some((error) => error)) {
      emailjs
        .sendForm('service_j8gq8rh', 'template_0s021dl', form.current, 'TAfVVQYOsK0uBn0uE')
        .then((result) => {
          console.log(result.text);
          if (result.text === 'OK') {
            alert('Message is successful Send');
          }
        })
        .catch((error) => {
          console.error('Failed to send the email:', error);
          alert('Failed to send the email. Please try again later.');
        });

      setFormData({
        leader_name: '',
        user_email: '',
        user_services: '',
        message: '',
      });
    }
  };


  return (
    <div className="container-fluid py-5">
    <div className="container py-5">
        <div className="text-center mb-3 pb-3">
            <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('contact')}</h6>
            <h1>{t('cont')}</h1>
        </div>
        <div className="row">
            <div className="col-lg-8">
                <div className="contact-form bg-white" style={{"padding": "30px"}}>
                    <div id="success"></div>
                    <form ref={form} onSubmit={sendEmail}>

                        <div className="form-row">
                            <div className="control-group col-sm-6">
                              <label for="name">{t('form_leader')}</label>
                              <input
                                    type="text"
                                    className={`form-control ${errors.leader_name ? 'red-input' : ''}`}
                                    name="leader_name"
                                    value={formData.leader_name}
                                    onChange={handleInputChange}
                                />

                            </div>
                            <div className="control-group col-sm-6">
                            <label for="email">{t('form_email')}</label>
                            <input type="email" name="user_email"
                                 className={`form-control ${errors.user_email ? 'red-input' : ''}`}
                                 value={formData.user_email}
                                 onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="control-group mt-3">
                        <label>{t('subject')}</label>
                        <input type="text" name="user_services"
                                 className={`form-control ${errors.user_services ? 'red-input' : ''}`}
                                 value={formData.user_services}
                                 onChange={handleInputChange} />
                        </div>
                        <div className="control-group mt-3">
                        <label for="message">{t('form_message')} *</label>
                                <textarea  cols="30" rows="5" name="message"  className={`form-control ${errors.message ? 'red-input' : ''}`}
                                 value={formData.message}
                                 onChange={handleInputChange}></textarea>
                        </div>
                        <div className="text-center mt-3">
                            <button className="btn btn-primary py-3 px-4" type="submit" id="sendMessageButton">{t('message')}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 mt-5 mt-lg-0">
            <div className="mb-5">
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <ul className="list-inline m-0">
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <p className="text-dark" href="#"><i className="fa fa-map-marker-alt  text-primary mr-2 mt-3"></i>7113 Street, Stone Town,Zanzibar</p>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <p className="text-dark" href="#"><i className="fa fa-phone-alt  text-primary mr-2 mt-3"></i>+255 620 362 827</p>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <p className="text-dark" href="#"><i className="fa fa-envelope  text-primary mr-2 mt-3"></i>info@zanzibarzmarkiem.com</p>
                                </li>
                             </ul>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ContactPage
