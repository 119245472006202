import React from 'react'
import Head from '../include/Head'
import Nav from '../include/Nav'
import Slider from '../zantour/Slider'
// import Booking from '../zantour/Booking'
import Zanpakage from '../zantour/Zanpakage'
import Footer from '../include/Footer'

const ZanTour = () => {
  return (
    <div>
      <Head />
      <Nav />
      <Slider />
      {/* <Booking /> */}
      <Zanpakage />
      <Footer />
    </div>
  )
}

export default ZanTour
