import React, { useState  } from 'react';
import TravelBooking from '../../TravelBooking';
import SafariBlue from '../../../tourplace/SafariBlue';
import { useTranslation } from "react-i18next";
const SafairBlueDetail = () => {

const {t} = useTranslation()
const [userServicesValue, setUserServicesValue] = useState("SAFARI BLUE, WITH LUNCH (FULL DAY)");

  
  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">
              
                    <SafariBlue />

    
                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">

                   <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('trip_plan')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2 "></i>{t('safari_plan5')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan7')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_plan8')}</p>
                        </div>
                    </div>

                    <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('safari_taken')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                           <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_taken1')}</p>
                           <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_taken2')}</p>
                           <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_taken3')}</p>
                           <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_taken4')}</p>
                           <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_taken5')}</p>
                           <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_taken6')}</p>
                          <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('safari_taken7')}</p>
                                   
                        </div>
                    </div>

                    <TravelBooking value={userServicesValue} /> 

                </div>
            </div>
        </div>
    </div>
  )
}

export default SafairBlueDetail
