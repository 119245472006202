import React, { useRef,useState  } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

const PlanForm = () => {
const {t} = useTranslation()

const form = useRef();

const [formData, setFormData] = useState({
    leader_name: '',
    user_email: '',
    phone_number:'', 
    user_address:'',
    adult:'',
    up4:'',
    income_date:'',
    out_date:'',
    message: ''
});

const [errors, setErrors] = useState({});

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear error when user starts typing
};



const validateForm = () => {
    const newErrors = {};

    // Validate leader name
    if (!formData.leader_name.trim()) {
      newErrors.leader_name = true;
    } else {
      newErrors.leader_name = false;
    }

    if (!formData.phone_number.trim()) {
        newErrors.phone_number = true;
      } else {
        newErrors.phone_number = false;
      }

      if (!formData.user_address.trim()) {
        newErrors.user_address = true;
      } else {
        newErrors.user_address = false;
      }

      if (!formData.adult.trim()) {
        newErrors.adult = true;
      } else {
        newErrors.adult = false;
      }

      if (!formData.up4.trim()) {
        newErrors.up4 = true;
      } else {
        newErrors.up4 = false;
      }

      if (!formData.income_date.trim()) {
        newErrors.income_date = true;
      } else {
        newErrors.income_date = false;
      }

      if (!formData.out_date.trim()) {
        newErrors.out_date = true;
      } else {
        newErrors.out_date = false;
      }
      
      if (!formData.message.trim()) {
        newErrors.message = true;
      } else {
        newErrors.message = false;
      }

    // Validate email format
    if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      newErrors.user_email = true;
    } else {
      newErrors.user_email = false;
    }

    // Other validations for different fields can be added here

    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    setErrors(newErrors);

    if (!Object.values(newErrors).some((error) => error)) {
      emailjs
        .sendForm('service_j8gq8rh', 'template_0s021dl', form.current, 'TAfVVQYOsK0uBn0uE')
        .then((result) => {
          console.log(result.text);
          if (result.text === 'OK') {
            alert('Booking is successful');
          }
        })
        .catch((error) => {
          console.error('Failed to send the email:', error);
          alert('Failed to send the email. Please try again later.');
        });

      setFormData({
        leader_name: '',
        user_email: '',
        phone_number:'', 
        user_address:'',
        adult:'',
        up4:'',
        income_date:'',
        out_date:'',
        message: '',
      });
    }
  };

    // Function to get today's date in the format "YYYY-MM-DD"
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();
  
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
  
      return `${year}-${month}-${day}`;
    };

  return (
    <div className="container-fluid py-5">
    <div className="container py-5">
        <div className="text-center mb-3 pb-3">
            <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('contact1')}</h6>
            <h1>{t('contact2')}</h1>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-8">
                <div className="contact-form bg-white" style={{"padding": "30px"}}>
                    <div id="success"></div>
                    <form ref={form} onSubmit={sendEmail}>
                            <div class="form-group">
                                <label for="name">{t('form_leader')}</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.leader_name ? 'red-input' : ''}`}
                                    name="leader_name"
                                    value={formData.leader_name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">{t('form_email')}</label>
                                <input type="email" name="user_email"
                                 className={`form-control ${errors.user_email ? 'red-input' : ''}`}
                                 value={formData.user_email}
                                 onChange={handleInputChange} />
                            </div>
                            <div class="form-group">
                                <label for="website">{t('form_phone')}</label>
                                <input type="text"  className={`form-control ${errors.phone_number ? 'red-input' : ''}`}
                                 value={formData.phone_number}
                                 onChange={handleInputChange} 
                                 name="phone_number"/>
                            </div>
                            <div class="form-group">
                                <label for="website">{t('form_address')}</label>
                                <input type="text"  className={`form-control ${errors.user_address ? 'red-input' : ''}`}
                                 value={formData.user_address}
                                 onChange={handleInputChange} 
                                 name="user_address" />
                            </div>

                            <div class="form-group">
                                <label for="website">{t('form_income_date')}</label>
                                <input type="date"  className={`form-control ${errors.income_date ? 'red-input' : ''}`}
                                 value={formData.income_date}
                                 onChange={handleInputChange} 
                                 name="income_date"    min={getCurrentDate()} />
                            </div>

                            <div class="form-group">
                                <label for="website">{t('form_out_date')}</label>
                                <input type="date"  className={`form-control ${errors.out_date ? 'red-input' : ''}`}
                                 value={formData.out_date}
                                 onChange={handleInputChange} 
                                 name="out_date" min={getCurrentDate()} />
                            </div>

                            <div class="form-group">
                                <label for="website">{t('form_adult')}</label>
                                <input type="number"  className={`form-control ${errors.adult ? 'red-input' : ''}`}
                                 value={formData.adult}
                                 onChange={handleInputChange} 
                                 name="adult" />
                            </div>
                            <div class="form-group">
                                <label for="website">{t('form_up4')}</label>
                                <input type="number"  className={`form-control ${errors.up4 ? 'red-input' : ''}`}
                                 value={formData.up4}
                                 onChange={handleInputChange} 
                                 name="up4" />
                            </div>
                           
                            <div class="form-group">
                                <input type="hidden"   name="user_services" value="TRIP PLAN"/>
                               
                            </div>
                            <div class="form-group">
                                <label for="message">{t('form_message')} *</label>
                                <textarea  cols="30" rows="5" name="message"  className={`form-control ${errors.message ? 'red-input' : ''}`}
                                 value={formData.message}
                                 onChange={handleInputChange}></textarea>
                            </div>
                            <div class="form-group mb-0">
                                <button type='submit' className="btn btn-primary font-weight-semi-bold py-2 px-3">{t('book')}</button>
                               
                            </div>
                        </form>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default PlanForm
