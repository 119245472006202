import React from 'react'
import Head from '../include/Head'
import Nav from '../include/Nav'
import Slider from './Slider'
import TranferForm from './TranferForm'
import Footer from '../include/Footer'

const Tranfer = () => {
  return (
    <div>
      <Head />
      <Nav />
      <Slider />
      <TranferForm />
      <Footer />
    </div>
  )
}

export default Tranfer
