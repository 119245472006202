import React, { useRef,useEffect,useState } from 'react';
import emailjs from '@emailjs/browser';
import '../../tourplace/image.css';
import { useTranslation } from "react-i18next";

const PhotoForm = () => {
const {t} = useTranslation()

const form = useRef();

const [formData, setFormData] = useState({
    leader_name: '',
    user_email: '',
    user_services: '',
    message: ''
});

const [errors, setErrors] = useState({});

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear error when user starts typing
};


const validateForm = () => {
    const newErrors = {};

    // Validate leader name
    if (!formData.leader_name.trim()) {
      newErrors.leader_name = true;
    } else {
      newErrors.leader_name = false;
    }

      if (!formData.user_services.trim()) {
        newErrors.user_services = true;
      } else {
        newErrors.user_services = false;
      }
      
      if (!formData.message.trim()) {
        newErrors.message = true;
      } else {
        newErrors.message = false;
      }

    // Validate email format
    if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      newErrors.user_email = true;
    } else {
      newErrors.user_email = false;
    }

    // Other validations for different fields can be added here

    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    setErrors(newErrors);

    if (!Object.values(newErrors).some((error) => error)) {
      emailjs
        .sendForm('service_j8gq8rh', 'template_0s021dl', form.current, 'TAfVVQYOsK0uBn0uE')
        .then((result) => {
          console.log(result.text);
          if (result.text === 'OK') {
            alert('Message is successful Send');
          }
        })
        .catch((error) => {
          console.error('Failed to send the email:', error);
          alert('Failed to send the email. Please try again later.');
        });

      setFormData({
        leader_name: '',
        user_email: '',
        user_services: '',
        message: '',
      });
    }
  };



useEffect(() => {
    const thumbnails = document.getElementById("thumbnails");
    const imgs = thumbnails.getElementsByTagName("img");
    const main = document.getElementById("main");

    for (let i = 0; i < imgs.length; i++) {
      const img = imgs[i];

      img.addEventListener("click", () => {
        main.src = img.src;
      });
    }
  }, []);

  return (
    <div className="container-fluid py-5">

        <div className="text-center mb-3 pb-3">
            <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('contact')}</h6>
            <h1>{t('contact_query')}</h1>
        </div>

        <div class="container pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                                 <img
                                  src="user/my_img/IMG-20230924-WA0151.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                <img
                                    src="user/my_img/IMG-20230924-WA0151.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0150.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0160.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0162.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0204.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0207.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0211.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0212.jpg"
                                    alt="Thumbnail 1"
                                  />

                              

                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
                            <p><b className='text-primary'>{t('phono_1')}</b></p>
                            <p>{t('photo_2')}</p>
                        </div>
        </div>

    <div className="container py-5">
    
        <div className="row justify-content-center">
            <div className="col-lg-8">
                <div className="contact-form bg-white" style={{"padding": "30px"}}>
                    <div id="success"></div>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-row mt-3">
                            <div className="control-group col-sm-6">
                             <label>{t('form_name')}</label>
                                <input type="text"
                                    className={`form-control ${errors.leader_name ? 'red-input' : ''}`}
                                    name="leader_name"
                                    value={formData.leader_name}
                                    onChange={handleInputChange} />

                            </div>
                            <div className="control-group col-sm-6">
                             <label>{t('form_email')}</label>
                                <input t type="email" name="user_email"
                                 className={`form-control ${errors.user_email ? 'red-input' : ''}`}
                                 value={formData.user_email}
                                 onChange={handleInputChange}  />
                            </div>
                        </div>
                        <div className="control-group mt-3">
                          <label>{t('form_sub')}</label>
                            <input type="text" name="user_services"
                                 className={`form-control ${errors.user_services ? 'red-input' : ''}`}
                                 value={formData.user_services}
                                 onChange={handleInputChange}/>

                        </div>
                        <div className="control-group mt-3">
                          <label>{t('form_message')}</label>
                            <textarea  cols="30" rows="5" name="message"  className={`form-control ${errors.message ? 'red-input' : ''}`}
                                 value={formData.message}
                                 onChange={handleInputChange}></textarea>
                                 
                        </div>
                        <div className="text-center mt-3">
                            <button className="btn btn-primary py-3 px-4" type="submit" id="sendMessageButton">{t('book')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default PhotoForm
