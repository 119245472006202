import React, { useState  } from 'react';
import './image.css';
import TravelBooking from '../TravelBooking';
import ZanzibarNature from '../../tourplace/ZanzibarNature';


const DetailPakage = () => {
  const [userServicesValue, setUserServicesValue] = useState("Nature of Zanzibar");

  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">

                    <ZanzibarNature />
                        
                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">
                   <TravelBooking value={userServicesValue} /> 
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default DetailPakage
