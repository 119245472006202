import React, { useState  } from 'react';
import TravelBooking from '../../TravelBooking';
// import TarangireSafari from '../../../tourplace/TarangireSafari';
import { useTranslation } from "react-i18next";
import Tangire5day from '../../../tourplace/Tangire5day';

const Tangire5daysDetail = () => {

const {t} = useTranslation()
const [userServicesValue, setUserServicesValue] = useState("5 days, 4noce: TARANGIRE PARKU +SERENGETI PARKU I NGORONGORO PARKU + LAKE MANYARA");
  
  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">

                    {/* <TarangireSafari /> */}
                    <Tangire5day />

                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">
                <TravelBooking value={userServicesValue} /> 

                   <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price')} {t('language_1')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <ul className="list-inline m-0">
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>2 {t('persons')} -2600$/{t('person')}</a>
                                  
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>3 {t('persons')} - 2250$/{t('person')}</a>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>4 {t('persons')} - 2100$/{t('person')}</a>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>5 {t('persons')} - 2000$/{t('person')}</a>
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>6 {t('persons')} - 1900$/{t('person')}</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
            
                    <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price')} {t('language_2')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <ul className="list-inline m-0">
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>2 {t('persons')} - $2850/{t('person')}</a>
                                  
                                </li>
                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>3 {t('persons')} - 2400$/{t('person')}</a>
                                </li>

                                <li className="mb-3 d-flex justify-content-between align-items-center">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>4 {t('persons')} - 2250$/{t('person')}</a>
                                </li>

                                <li className="mb-3 d-flex justify-content-between align-items-center mt-2">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>5 {t('persons')} -2150$/{t('person')}</a>
                                </li>

                                <li className="mb-3 d-flex justify-content-between align-items-center mt-2">
                                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-primary mr-2"></i>6 {t('persons')} -2050$/{t('person')}</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Tangire5daysDetail
