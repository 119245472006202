import React, { useEffect } from 'react';
import '../image.css';

const SafariBlue = () => {
    
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            <img
                                  src="user/my_img/IMG-20230924-WA0217.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/IMG-20230924-WA0217.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/20191028_134215.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/IMG-20200705-WA0245.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0144.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0219.jpg"
                                    alt="Thumbnail 4"
                                  />  
                                   <img
                                    src="user/my_img/IMG-20230924-WA0231.jpg"
                                    alt="Thumbnail 5"
                                  /> 
                                   <img
                                    src="user/my_img/IMG-20230924-WA0250.jpg"
                                    alt="Thumbnail 6"
                                  /> 
                                  
                                  <img
                                    src="user/my_img/FB_IMG_15972245523045205.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_15782509080035885.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/received_892362514568202_1.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0163.jpg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0217.jpg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 12.33.11.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 12.34.36.jpeg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0250.jpg"
                                    alt="Thumbnail 6"
                                  />

<img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (15).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 17.41.39 (1).jpeg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 17.41.39.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 17.41.40 (1).jpeg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 17.41.40 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />

<img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 17.41.40 (3).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 17.41.40 (4).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 17.41.40.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.55.02 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-19 at 12.31.08 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />

                                </div>
                            </div>
                        </div>
                       
                    </div>
    </div>
  )
}

export default SafariBlue