import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";

const Serengeti3 = () => {
const {t} = useTranslation()

    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            <img
                                  src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.56.30.jpeg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.56.30.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0190.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.57.03.jpeg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.57.55 (1).jpeg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.57.55.jpeg"
                                    alt="Thumbnail 4"
                                  />  
                                   <img
                                    src="user/my_img/IMG-20230924-WA0193.jpg"
                                    alt="Thumbnail 4"
                                  />   
                                    <img
                                    src="user/my_img/IMG-20230924-WA0198.jpg"
                                    alt="Thumbnail 4"
                                  />                                      
                                  <img
                                  src="user/my_img/IMG-20230924-WA0191.jpg"
                                  alt="Thumbnail 4"
                                />         

                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
          
                            <h2 class="mb-3">3 days / 2 days: SERENGETI PARKU +TARANGIRE PARKU + NGORONGORO PARKU</h2>
                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('day')} 1</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_5')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_7')}</p>
                            <hr />
                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('days')} 2</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day2_1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day2_2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day1_6')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day2_4')}</p>
                            <hr />
                            <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('days')} 3</h4>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('serengeni3_day3_5')}</p>
                            <hr />

                                <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price_include')}</h4>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous2_day2_price_1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include2')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('mikumisafari_inlude1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include4')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('selous_include5')}</p>
                                
                                <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('price_with')}</h4>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_1')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_2')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_3')}</p>
                                <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('price_wothout_4')}</p>
                        </div>
                    </div>

    </div>
  )
}

export default Serengeti3
