import React from 'react'
import {NavLink} from "react-router-dom";
import { useTranslation } from "react-i18next";

const Nav = () => {
    const {t} = useTranslation()
  return (
    <div className="container-fluid position-relative nav-bar p-0"  style={{'marginTop': '-10px'}}>
        <div className="container-lg position-relative p-0 px-lg-3" style={{"z-index": "9"}}>
          <nav className="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5">
              <NavLink to="/" className="navbar-brand">
                  {/* <h1 className="m-0 text-primary"><span className="text-dark">Zanzibar</span>Markrem</h1> */}
                  <img className="img-fluid" src="user/my_img/Zanzibar&Markiem.png"  width="100" height="auto" />
              </NavLink>
              <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                  <div className="navbar-nav ml-auto py-0">
                      <NavLink to="/" className="nav-item nav-link ">{t("home")}</NavLink>
                      {/* <NavLink to="/ZanzibarTour" className="nav-item nav-link">Zan Tour</NavLink>
                      <NavLink to="/TanzaniaSafari" className="nav-item nav-link">Tan Safari</NavLink> */}
                      <div className="nav-item dropdown">
                          <NavLink to="" className="nav-link dropdown-toggle" data-toggle="dropdown">{t('services')}</NavLink>
                          <div className="dropdown-menu border-0 rounded-0 m-0">
                              {/* <NavLink to="/Hotel" className="dropdown-item">Hotel Booking</NavLink>
                              <NavLink to="/Bangalow" className="dropdown-item">Bungalow</NavLink> */}
                              <NavLink to="/ZanzibarTour" className="dropdown-item">{t('zan_tour')}</NavLink>
                              <NavLink to="/TanzaniaSafari" className="dropdown-item">{t('tan_tour')}</NavLink>
                              {/* <NavLink to="/Bangalow" className="dropdown-item">{t('accommodation')}</NavLink> */}
                              <NavLink to="/Tranfer" className="dropdown-item">{t('tranfers')}</NavLink>
                              <NavLink to="/Photoshot&Drown" className="dropdown-item">{t('photoshot_drawn')}</NavLink>
                          </div>
                      </div>
                      {/* <NavLink to="/Tranfer" className="nav-item nav-link">Transfer</NavLink> */}
                      <NavLink to="/Contact" className="nav-item nav-link">{t('contact')}</NavLink>
                      <NavLink to="/PlanTravel" className="nav-item nav-link">{t('Plan_your_trip')}</NavLink>
                  </div>
              </div>
          </nav>
        </div>
     </div>
  )
}
export default Nav
