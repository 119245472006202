import React from 'react'
import { useTranslation } from "react-i18next";

const Service = () => {
    const {t} = useTranslation()

  return (
    <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
            <div className="text-center mb-3 pb-3">
                <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('services')}</h6>
                <h1>{t('tour_services')}</h1>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="service-item bg-white text-center mb-2 py-5 px-4">
                        <i className="fa fa-2x fa-route mx-auto mb-4"></i>
                        <h5 className="mb-2">{t('travel_guide')}</h5>
                        <p className="m-0">{t('travel_guide_desc')}</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="service-item bg-white text-center mb-2 py-5 px-4">
                        <i className="fa fa-2x fa-ticket-alt mx-auto mb-4"></i>
                        <h5 className="mb-2">{t('tranfers')} </h5>
                        <p className="m-0">{t('tranfers_desc')} </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                    <div className="service-item bg-white text-center mb-2 py-5 px-4">
                        <i className="fa fa-2x fa-hotel mx-auto mb-4"></i>
                        <h5 className="mb-2">{t('accommodation')}</h5>
                        <p className="m-0">{t('accommodation_desc')}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Service
