import React from 'react'
import { useTranslation } from "react-i18next";

const Slider = () => {
const {t} = useTranslation()

  return (
    <div className="container-fluid p-0">
    <div id="header-carousel" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                 <img className="w-100" src="user/my_img/20201023_120110.jpg" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{"max-width": "900px"}}>
                        <h4 className="text-white text-uppercase mb-md-3">{t('bunner_tour')}</h4>
                        <h1 className="display-3 text-white mb-md-4">{t('bunner_tour1')}</h1>
                        <a href="" className="btn btn-primary py-md-3 px-md-5 mt-2">{t('book_now')}</a>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="user/my_img/FB_IMG_15972245523045205.jpg" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{"max-width": "900px"}}>
                        <h4 className="text-white text-uppercase mb-md-3">{t('bunner_tour')}</h4>
                        <h1 className="display-3 text-white mb-md-4">{t('bunner_tour2')}</h1>
                        <a href="" className="btn btn-primary py-md-3 px-md-5 mt-2">{t('book_now')}</a>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="user/my_img/IMG-20230924-WA0232.jpg" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{"max-width": "900px"}}>
                        <h4 className="text-white text-uppercase mb-md-3">{t('bunner_tour')}</h4>
                        <h1 className="display-3 text-white mb-md-4">{t('bunner_tour2')}</h1>
                        <a href="" className="btn btn-primary py-md-3 px-md-5 mt-2">{t('book_now')}</a>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="user/my_img/IMG-20200327-WA0011.jpg" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{"max-width": "900px"}}>
                        <h4 className="text-white text-uppercase mb-md-3">{t('bunner_tour')}</h4>
                        <h1 className="display-3 text-white mb-md-4">{t('bunner_tour2')}</h1>
                        <a href="" className="btn btn-primary py-md-3 px-md-5 mt-2">{t('book_now')}</a>
                    </div>
                </div>
            </div>
        </div>
        <a className="carousel-control-prev" href="#header-carousel" data-slide="prev">
            <div className="btn btn-dark" style={{"width": "45px","height": "45px"}}>
                <span className="carousel-control-prev-icon mb-n2"></span>
            </div>
        </a>
        <a className="carousel-control-next" href="#header-carousel" data-slide="next">
            <div className="btn btn-dark" style={{"width": "45px","height": "45px"}}>
                <span className="carousel-control-next-icon mb-n2"></span>
            </div>
        </a>
    </div>
</div>
  )
}

export default Slider
