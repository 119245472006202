import React from 'react'
import Slider from './Slider'
import Head from '../../include/Head'
import Nav from '../../include/Nav'
import Footer from '../../include/Footer'
import SafairBlueDetail from './SafairBlueDetail'

const SafairBlueTourDetail = () => {
  return (
    <div>
   <Head/>
   <Nav />
      <Slider />
      <SafairBlueDetail/>
      <Footer />
    </div>
  )
}

export default SafairBlueTourDetail
