import React from 'react'
import Slider from './Slider'
import DetailPakage from './DetailPakage'
import Head from '../include/Head'
import Nav from '../include/Nav'
import Footer from '../include/Footer'

const ZanTourDetail = () => {
  return (
    <div>
   <Head/>
   <Nav />
      <Slider />
      <DetailPakage/>
      <Footer />
    </div>
  )
}

export default ZanTourDetail
