import React from 'react'
import Head from '../include/Head'
import Nav from '../include/Nav'
import ContactPage from './ContactPage'
import Slider from './Slider'
import Footer from '../include/Footer'

const Contact = () => {
  return (
    <div>
      <Head />
      <Nav />
      <Slider />
      <ContactPage />
    <Footer />
     </div>
  )
}

export default Contact
