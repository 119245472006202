import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";

const SafariBlue = () => {

  const {t} = useTranslation()
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                                 <img
                                  src="user/my_img/FB_IMG_15972245523045205.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/FB_IMG_15972245523045205.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_15782509080035885.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/received_892362514568202_1.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0163.jpg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0217.jpg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 12.33.11.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 12.34.36.jpeg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0250.jpg"
                                    alt="Thumbnail 6"
                                  />

                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
        
                            <p><b className='text-primary'>{t('safari_title')}: </b>{t('safari_blue')}</p>
                        </div>
                    </div>

    </div>
  )
}

export default SafariBlue
