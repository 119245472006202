import React from 'react'
import Slider from './Slider'

import Head from '../../include/Head'
import Nav from '../../include/Nav'
import Footer from '../../include/Footer'
import AquaticSafari from './AquaticSafari'

const AquaticSafariGalleryTour = () => {
  return (
    <div>
      <Head/>
      <Nav />
      <Slider />
      <AquaticSafari />
      <Footer />
    </div>
  )
}

export default AquaticSafariGalleryTour
