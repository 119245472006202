import React from 'react'
import Slider from './Slider'
import Head from '../../include/Head'
import Nav from '../../include/Nav'
import Footer from '../../include/Footer'
import PungumeDetail from './PungumeDetail'

const PungumeTourDetail = () => {
  return (
    <div>
   <Head/>
   <Nav />
      <Slider />
      <PungumeDetail/>
      <Footer />
    </div>
  )
}

export default PungumeTourDetail
