import React from 'react'
import {NavLink} from "react-router-dom";
import { useTranslation } from "react-i18next";
const Tanpakage = () => {
const {t} = useTranslation()

  return (
    <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
            <div className="text-center mb-3 pb-3">
                <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('pakage')}</h6>
                <h1>{t('tan_tour')}</h1>
            </div>
            <div className="row">

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0170.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>1 {t('day')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/Selous1day">SELOUS PARK (NYERERE)</NavLink>
                        <p>1-day safari in SELOUS PARK(nyerere) departure from Zanzibar</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                 </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0199_1.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>3 {t('days')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/MikumiSafari"> MIKUMI PARK</NavLink>
                        <p>1-day safari in MIKUMI PARK departure from Zanzibar</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0190.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>2 {t('days')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/Selous2day">SELOUS PARK</NavLink>
                        <p>2 days / 1 night Safari in SELOUS PARK departure from Zanzibar</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (5).jpeg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>3 {t('days')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/Serengeti3day">SERENGETI PARKU +TARANGIRE PARKU + NGORONGORO PARKU</NavLink>
                        <p>3 days / 2 days: SERENGETI PARKU +TARANGIRE PARKU + NGORONGORO PARKU</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0229.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>3 {t('days')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/MikumiSafari3day">LAKE MANYARA + TARANGIRE PARKU + NGORONGORO PARKU</NavLink>
                        <p>3 days /2noce: LAKE MANYARA + TARANGIRE PARKU + NGORONGORO PARKU</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0193.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>3 {t('days')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/TarangireSafari">TARANGIRE PARKU + SERENGETI PARKU + NGORONGORO PARKU</NavLink>
                        <p>4 days / 3 days : TARANGIRE PARKU + SERENGETI PARKU + NGORONGORO PARKU</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="package-item bg-white mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0169.jpg" alt="" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>Tanzania</small>
                            <small className="m-0"><i className="fa fa-calendar-alt text-primary mr-2"></i>3 {t('days')}</small>
                            {/* <small className="m-0"><i className="fa fa-user text-primary mr-2"></i>2 Person</small> */}
                        </div>
                        <NavLink className="h5 text-decoration-none" to="/Tarangire5daysSafari">TARANGIRE PARKU +SERENGETI PARKU I NGORONGORO PARKU + LAKE MANYARA</NavLink>
                        <p>5 days /4noce: TARANGIRE PARKU +SERENGETI PARKU I NGORONGORO PARKU + LAKE MANYARA</p>
                        <div className="border-top mt-4 pt-4">
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                <h5 className="m-0">$350</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>



            </div>
        </div>
    </div>
  )
}

export default Tanpakage
