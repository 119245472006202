import React, { useEffect } from 'react';
import '../image.css';

const WealthZanzibarGallery = () => {
    
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            
                             <img
                                  src="user/my_img/20191026_105613.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/20191026_105613.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_15981050673009164.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_16198417882191959.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_16336683063216823.jpg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0248.jpg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0249.jpg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0232.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/20191028_134215.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0167.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0161.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20220810-WA0028.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0179.jpg"
                                    alt="Thumbnail 6"
                                  />

                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (24).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (23).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (22).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (16).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.15 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.28 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.28 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />

<img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.28 (3).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.28 (4).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/20201023_120110.jpg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/bacWealth/WhatsApp Image 2023-10-23 at 12.15.20.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0247.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   
                                </div>
                            </div>
                        </div>
                       
                    </div>
    </div>
  )
}

export default WealthZanzibarGallery