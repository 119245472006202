import React, { useState  } from 'react';
import TravelBooking from '../../TravelBooking';
import WealthZanzibar from '../../../tourplace/WealthZanzibar';
import { useTranslation } from "react-i18next";
const WealthDetail = () => {
    const {t} = useTranslation()
  const [userServicesValue, setUserServicesValue] = useState("WEALTH OF ZANZIBAR");

  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">
              
            
                    <WealthZanzibar />
                      
                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">

                    <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('trip_plan')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
        
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('wealth_plan')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('wealth_plan1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('wealth_plan2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('wealth_plan3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('wealth_plan4')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('wealth_plan5')}</p>
                                                          
                        </div>
                    </div>

                    <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('prepare_title')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('welth_prepare')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('welth_prepare1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('welth_prepare2')}</p>
                 
                        </div>
                    </div>

                    <TravelBooking value={userServicesValue} /> 

                </div>
            </div>
        </div>
    </div>
  )
}

export default WealthDetail
