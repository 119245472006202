import React, { useEffect } from 'react';
// import { useTranslation } from "react-i18next";
import i18next from 'i18next'
import cookies from 'js-cookie'
import { useLocation } from 'react-router-dom';
const Head = () => {
  const location = useLocation();

  useEffect(() => {
    
    window.scrollTo(0, 0); // Scrolls to the top of the page
    updateHead();
  }, [location]);

  const updateHead = () => {
   
  };
    // const {t} = useTranslation()
  
    const languages = [
      {
        code: 'pl',
        name: 'Polish',
        country_code: 'pl',
      },
      {
        code: 'en',
        name: 'English',
        country_code: 'gb',
      },
      {
        code: 'fr',
        name: 'Français',
        country_code: 'fr',
      },
    //   {
    //     code: 'ar',
    //     name: 'العربية',
    //     dir: 'rtl',
    //     country_code: 'sa',
    //   },
     
    ]
    const currentLanguageCode = cookies.get('i18next') || 'en'
    
  return (
    <div>
    <div className='container bg-light pt-2 d-lg-none'> {/* Hide on large screens */}
    <div className='row'>
        <div className="col-lg-12 text-lg-left mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center">
        
                <p><i className="fa fa-phone-alt mr-2" style={{'font-size': '12px'}}></i>+255 620 362 827</p>
                <p className="text-primary text-body px-3"style={{'margin-left': '20px'}}>|</p>
                  {languages.map(({ code , country_code}) => (
                            <p className='' key={country_code}>
                              <button key={country_code} className='btn btn-xsm'
                                  onClick={() =>i18next.changeLanguage(code)} >
                                      <span className={`flag-icon flag-icon-${country_code}`}
                                          style={{ opacity: currentLanguageCode === code ? 0.5 : 1,}} style={{'font-size': '12px'}}>
                                      </span>  
                              </button>
                            </p>
                          ))}         
            </div>
        </div><hr />
        <div className="col-lg-6 text-lg-left"style={{'font-size': '12px','margin-top':'-25px'}}>
            <div className="d-inline-flex align-items-center">
                <p><i className="fa fa-envelope mr-2"></i>info@zanzibarzmarkiem.com</p>
                {/* <p className="text-body px-3">|</p>
                <p><i className="fa fa-phone-alt mr-2"></i>+255 620 362 827</p> */}
            </div>
        </div>
    </div>
</div>
<div className='container bg-light pt-3 d-none d-lg-block'> {/* Hide on smaller screens */}
    <div className='row'>
        <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center">
                <p><i className="fa fa-envelope mr-2"></i>info@zanzibarzmarkiem.com</p>
                <p className="text-body px-3">|</p>
                <p><i className="fa fa-phone-alt mr-2"></i>+255 620 362 827</p>
            </div>
        </div>
        <div className="col-lg-6 text-center text-lg-right">
            <div className="d-inline-flex align-items-center" style={{'marginTop': '-20px'}}>
            {languages.map(({ code, name , country_code}) => (
                        <p className='m-2' key={country_code}>
                          <button key={country_code} className='btn btn-xsm'
                              onClick={() =>i18next.changeLanguage(code)} >
                                  <span className={`flag-icon flag-icon-${country_code} mx-2`}
                                      style={{ opacity: currentLanguageCode === code ? 0.5 : 1,}}>
                                  </span>  {name}
                          </button>
                        </p>
                      ))}
            </div>
        </div>
    </div>
</div>
</div>

  )
}

export default Head