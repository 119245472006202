import React from 'react'
import Head from '../include/Head'
import Nav from '../include/Nav'
import Slider from './Slider'
import HatelPakage from './HatelPakage'
import Footer from '../include/Footer'

const Hotel = () => {
  return (
    <div>
      <Head />
      <Nav />
      <Slider/>
      <HatelPakage />
      <Footer/>
    </div>
  )
}

export default Hotel
