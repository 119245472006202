import React from 'react'
import { useTranslation } from "react-i18next";
import {NavLink} from "react-router-dom";
const Popular = () => {
    const {t} = useTranslation()

  return (
    <div className="container-fluid">
    <div className="container pt-5 pb-3">
        <div className="text-center mb-3 pb-3">
            <h6 className="text-primary text-uppercase" style={{"letter-spacing": "5px"}}>{t('tour_tile')}</h6>
            <h1>{t('gallery')}</h1>
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="destination-item position-relative overflow-hidden mb-2">
                    <img className="img-fluid" src="user/my_img/PUNGUME_1.jpg" alt="" />
                    <NavLink className="destination-overlay text-white text-decoration-none" to="/PrisonIslandGallery">
                        <h5 className="text-white">PHOTOSHOOT & DRONE</h5>
                        {/* <span>100 Cities</span> */}
                    </NavLink>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="destination-item position-relative overflow-hidden mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20200705-WA0245_1.jpg" alt="" />
                    <NavLink className="destination-overlay text-white text-decoration-none" to="/SafariBlueGallery">
                        <h5 className="text-white">SAFARI BLUE</h5>
                        {/* <span>100 Cities</span> */}
                    </NavLink>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="destination-item position-relative overflow-hidden mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0162.jpg" alt="" />
                    <NavLink className="destination-overlay text-white text-decoration-none" to="/WealthZanzibarGallery">
                        <h5 className="text-white">WEALTH OF ZANZIBAR</h5>
                        {/* <span>100 Cities</span> */}
                    </NavLink>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="destination-item position-relative overflow-hidden mb-2">
                    <img className="img-fluid" src="user/my_img/NATURE_1.jpg" alt="" />
                    <NavLink className="destination-overlay text-white text-decoration-none" to="/NatureZanzibarGallery">
                        <h5 className="text-white">NATURE OF ZANZIBAR</h5>
                        {/* <span>100 Cities</span> */}
                    </NavLink>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="destination-item position-relative overflow-hidden mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0237_1.jpg" alt="" />
                    <NavLink className="destination-overlay text-white text-decoration-none" to="/AquaticSafariGallery">
                        <h5 className="text-white">AQUATIC SAFARI</h5>
                        {/* <span>100 Cities</span> */}
                    </NavLink>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="destination-item position-relative overflow-hidden mb-2">
                    <img className="img-fluid" src="user/my_img/IMG-20230924-WA0196_1.jpg" alt="" />
                    <NavLink className="destination-overlay text-white text-decoration-none" to="/TanzaniaSafariGallery">
                        <h5 className="text-white">SAFARI</h5>
                        {/* <span>100 Cities</span> */}
                    </NavLink>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Popular
