import React, { useEffect } from 'react';
import './image.css';
import { useTranslation } from "react-i18next";

const ZanzibarNature = () => {
  const {t} = useTranslation()

    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);
  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                                 <img
                                  src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (8).jpeg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (8).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (12).jpeg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/IMG-20200327-WA0011.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (14).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0168.jpg"
                                    alt="Thumbnail 5"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0159.jpg"
                                    alt="Thumbnail 6"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0205.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.07.19.jpeg"
                                    alt="Thumbnail 6"
                                  />

                                </div>
                            </div>
                        </div>
                        <div class="bg-white mb-3" style={{"padding": "30px"}}>
                        
                             <p><b className='text-primary'>JOZANI FOREST:</b> {t('jozani_desc')}</p><hr />
                             <p><b className='text-primary'>ROCK RESTAURANT: </b>{t('rock_desc')}</p><hr />
                             <p><b className='text-primary'>PAJE BEACH:</b> {t('paje_desc')}</p><hr />
                             <p><b className='text-primary'>SEAWEED:</b> {t('seaweed_desc')}</p> <hr />



                        </div>
                    </div>
                    

    </div>
  )
}

export default ZanzibarNature
