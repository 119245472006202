import React, { useState  } from 'react';
import TravelBooking from '../../TravelBooking';
import AquaticSafari from '../../../tourplace/AquaticSafari';
// import Mnemba from '../../../tourplace/Mnemba';
// import NungwiAquarium from '../../../tourplace/NungwiAquarium';
// import KendwaBeach from '../../../tourplace/KendwaBeach';
import { useTranslation } from "react-i18next";

const AquaticDetail = () => {
    const {t} = useTranslation()
    const [userServicesValue, setUserServicesValue] = useState("AQUATIC SAFARI WITH LUNCH");
  
  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">

                    {/* <Mnemba />
                    <NungwiAquarium />
                    <KendwaBeach />   */}
                    <AquaticSafari />

                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">

                   <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('trip_plan')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                           <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('aquatic_plan1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('aquatic_plan2')}</p>                                 
                        </div>
                    </div>

                    <div className="mb-5">
                        <h4 className="text-uppercase mb-4" style={{"letter-spacing": "5px"}}>{t('prepare_title')}</h4>
                        <div className="bg-white" style={{"padding": "30px"}}>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('aquatic_prepare1')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('aquatic_prepare2')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('aquatic_prepare3')}</p>
                            <p><i className="fa fa-angle-right text-primary mr-2"></i>{t('aquatic_prepare4')}</p>
                             
                        </div>
                    </div>

                    <TravelBooking value={userServicesValue} /> 

                </div>
            </div>
        </div>
    </div>
  )
}

export default AquaticDetail
