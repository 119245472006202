import React, { useEffect } from 'react';
import '../image.css';

const TanSafariGallery = () => {
    
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">

                            <img
                                  src="user/my_img/IMG-20230924-WA0170.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/IMG-20230924-WA0170.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0169.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0182.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0245.jpg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.56.22.jpeg"
                                    alt="Thumbnail 4"
                                  />

                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.56.05.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.55.05.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.54.15.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.53.29.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 15.24.31.jpeg"
                                    alt="Thumbnail 4"
                                  />

                                  <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.57.55.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.57.55 (1).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.57.03.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.56.30.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.54.59.jpeg"
                                    alt="Thumbnail 4"
                                  />

                                  <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.51.17.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 14.49.13.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.44 (2).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.20.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.44 (1).jpeg"
                                    alt="Thumbnail 4"
                                  />

                                  <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.20 (7).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.20 (2).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.19.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.06.19 (1).jpeg"
                                    alt="Thumbnail 4"
                                  />
                                   <img
                                     src="user/my_img/safari/WhatsApp Image 2023-10-23 at 13.04.18.jpeg"
                                    alt="Thumbnail 4"
                                  />
                                      
                                </div>
                            </div>
                        </div>
                       
                    </div>
    </div>
  )
}

export default TanSafariGallery