import React, { useEffect } from 'react';
import '../image.css';

const PrisonIslandGallery = () => {
    
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            <img
                                  src="user/my_img/IMG-20230924-WA0151.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/IMG-20230924-WA0151.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0150.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0160.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0162.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 11.03.10.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0207.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0211.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0212.jpg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0213.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (11).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (10).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.52.07 (9).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.00.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.01 (1).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.01.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.02 (1).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.02 (2).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.02 (3).jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.02.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 10.55.44.jpeg"
                                    alt="Thumbnail 1"
                                  /> 
                                  <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 11.03.11.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                   <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 11.03.09.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 11.03.06.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/photo/WhatsApp Image 2023-10-24 at 11.02.59.jpeg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0224.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  
                                  
                                   
                                </div>
                            </div>
                        </div>
                       
                    </div>
    </div>
  )
}

export default PrisonIslandGallery