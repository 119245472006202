import React, { useEffect } from 'react';
import '../image.css';

const AquaticSafari = () => {
    
    useEffect(() => {
        const thumbnails = document.getElementById("thumbnails");
        const imgs = thumbnails.getElementsByTagName("img");
        const main = document.getElementById("main");
    
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
    
          img.addEventListener("click", () => {
            main.src = img.src;
          });
        }
      }, []);

  return (
    <div>
      <div class="pb-3">
                        <div class="blog-item">
                            <div class="position-relative">
                            <img
                                  src="user/my_img/IMG-20230924-WA0235.jpg" id="main" alt="Main Image" />
                                <div id="thumbnails">
                                  <img
                                    src="user/my_img/IMG-20230924-WA0235.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0234.jpg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/FB_IMG_15981058443279415.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0162.jpg"
                                    alt="Thumbnail 4"
                                  />
                                  <img
                                    src="user/my_img/IMG-20230924-WA0204.jpg"
                                    alt="Thumbnail 5"
                                  />
                                 <img
                                    src="user/my_img/IMG-20230924-WA0237.jpg"
                                    alt="Thumbnail 1"
                                  />
                                  <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.53.27.jpeg"
                                    alt="Thumbnail 2"
                                  />
                                  <img
                                    src="user/my_img/received_892362514568202_1.jpg"
                                    alt="Thumbnail 3"
                                  />
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.55.02.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                  
                                  <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.55.02 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/received_892362514568202_1.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/WhatsApp Image 2023-10-19 at 12.31.08 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/IMG-20230924-WA0240.jpg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/WhatsApp Image 2023-10-19 at 12.31.08.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.08.49.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.28.jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/new_pc/WhatsApp Image 2023-10-23 at 17.27.30 (3).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.49.27 (15).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/pc/WhatsApp Image 2023-10-23 at 07.53.26 (1).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                   <img
                                    src="user/my_img/WhatsApp Image 2023-10-23 at 12.55.02 (2).jpeg"
                                    alt="Thumbnail 6"
                                  />
                                </div>
                            </div>
                        </div>
                       
                    </div>
    </div>
  )
}

export default AquaticSafari