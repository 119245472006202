import React, { useState  } from 'react';
import TravelBooking from '../../TravelBooking';
import Mikumi from '../../../tourplace/Mikumi';
const MikumiDetail = () => {
  const [userServicesValue, setUserServicesValue] = useState("1-day safari in MIKUMI PARK");
  
  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">

                    <Mikumi />

                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">
                <TravelBooking value={userServicesValue} /> 
                  
                </div>
            </div>
        </div>
    </div>
  )
}

export default MikumiDetail
