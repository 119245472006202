import React, { useState  } from 'react';
import TravelBooking from '../../TravelBooking';
import SelousPark2 from '../../../tourplace/SelousPark2';

const Selous2Detail = () => {
    const [userServicesValue, setUserServicesValue] = useState("2 days, 1 night Safari in SELOUS PARK");
  
  return (
      <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-8">

                    <SelousPark2 />

                </div>
    
                <div class="col-lg-4 mt-5 mt-lg-0">
                   <TravelBooking value={userServicesValue} /> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default Selous2Detail
